// extracted by mini-css-extract-plugin
export var content = "app-landing-page-module--content--2Sht0";
export var body = "app-landing-page-module--body--3s10E";
export var desktopOnly = "app-landing-page-module--desktopOnly--2SSdk";
export var mobileOnly = "app-landing-page-module--mobileOnly--1Oq9Y";
export var section = "app-landing-page-module--section--2lRu9";
export var item = "app-landing-page-module--item--1TBDU";
export var featuresList = "app-landing-page-module--featuresList--3U_2w";
export var listHeadline = "app-landing-page-module--listHeadline--1AU4g";
export var featuresListItem = "app-landing-page-module--featuresListItem--3TcGm";
export var screenshotsList = "app-landing-page-module--screenshotsList--1T592 app-landing-page-module--item--1TBDU";
export var screenshotItem = "app-landing-page-module--screenshotItem--hzNcA";
export var cta = "app-landing-page-module--cta--1m-P8 app-landing-page-module--item--1TBDU";
export var faqSection = "app-landing-page-module--faqSection--uPzCI";
export var faqTitle = "app-landing-page-module--faqTitle--20KUU";
export var faqQuestion = "app-landing-page-module--faqQuestion--184NC";
export var breadCrumbs = "app-landing-page-module--breadCrumbs--2Nrgr";