import React from "react"
import PropTypes from "prop-types"
import Layout from "../components/layout"
import AppStoreCta from "../components/app-store-cta"

import {
  body,
  content,
  featuresList,
  featuresListItem,
  screenshotsList,
  section,
  item,
  listHeadline,
  faqSection,
  faqQuestion,
  faqTitle,
  breadCrumbs,
} from "./app-landing-page.module.css"
import { Link } from "gatsby"

const AppLandingPage = ({
  title,
  description,
  features,
  screenshots,
  subheadline,
  subfeatures,
  conversionLink,
  subfeaturesHeadline,
  featuresHeadline,
  breadcrumbs,
}) => (
  <Layout
    bodyClassName={body}
    contentClassName={content}
    footerChildren={
      <div>
        <Link to="/privacy-policy">Privacy Policy</Link>
      </div>
    }
  >
    <h1>{title}</h1>
    <h2>{description}</h2>
    <div className={section}>
      <div className={screenshotsList}>{screenshots}</div>
    </div>
    <div className={item}>
      <AppStoreCta linkTo={conversionLink} />
    </div>
    <div className={item}>
      <h3>{subheadline}</h3>
    </div>
    <div className={breadCrumbs}>{breadcrumbs}</div>
    <div className={section}>
      <div className={item}>
        <h4 className={listHeadline}>{featuresHeadline}</h4>
        <ul className={featuresList}>
          {features.map((value, index) => (
            <li className={featuresListItem} key={index}>
              {value}
            </li>
          ))}
        </ul>
      </div>
      <div className={item}>
        <h4 className={listHeadline}>{subfeaturesHeadline}</h4>
        <ul className={featuresList}>
          {subfeatures.map((value, index) => (
            <li className={featuresListItem} key={index}>
              {value}
            </li>
          ))}
        </ul>
      </div>
    </div>

    <div className={faqSection}>
      <h4 className={faqTitle}>Tabata Frequently Asked Questions (FAQ)</h4>

      <div
        itemScope
        itemProp="mainEntity"
        itemType="https://schema.org/Question"
      >
        <h5 itemProp="name" className={faqQuestion}>
          What is Tabata?
        </h5>
        <div
          itemScope
          itemProp="acceptedAnswer"
          itemType="https://schema.org/Answer"
        >
          <div itemProp="text">
            Tabata is a type of high-intensity interval training (HIIT) that
            alternates between 20 seconds of intense exercise and 10 seconds of
            rest, for a total of four minutes.
          </div>
        </div>
      </div>

      <div
        itemScope
        itemProp="mainEntity"
        itemType="https://schema.org/Question"
      >
        <h5 itemProp="name" className={faqQuestion}>
          What are some examples of exercises that can be performed during a
          Tabata workout?
        </h5>
        <div
          itemScope
          itemProp="acceptedAnswer"
          itemType="https://schema.org/Answer"
        >
          <div itemProp="text">
            Some examples of exercises that can be performed during a Tabata
            workout include running, cycling, jumping jacks, squats, and
            burpees.
          </div>
        </div>
      </div>

      <div
        itemScope
        itemProp="mainEntity"
        itemType="https://schema.org/Question"
      >
        <h5 itemProp="name" className={faqQuestion}>
          How many times per week should someone do Tabata to see results?
        </h5>
        <div
          itemScope
          itemProp="acceptedAnswer"
          itemType="https://schema.org/Answer"
        >
          <div itemProp="text">
            It is recommended to do Tabata 2-3 times a week, with 1-2 days of
            rest in between sessions.
          </div>
        </div>
      </div>

      <div
        itemScope
        itemProp="mainEntity"
        itemType="https://schema.org/Question"
      >
        <h5 itemProp="name" className={faqQuestion}>
          What are the benefits of Tabata training?
        </h5>
        <div
          itemScope
          itemProp="acceptedAnswer"
          itemType="https://schema.org/Answer"
        >
          <div itemProp="text">
            The benefits of Tabata training include improved cardiovascular
            fitness, increased endurance and muscle tone, and increased calorie
            burning.
          </div>
        </div>
      </div>

      <div
        itemScope
        itemProp="mainEntity"
        itemType="https://schema.org/Question"
      >
        <h5 itemProp="name" className={faqQuestion}>
          Is Tabata suitable for beginners?
        </h5>
        <div
          itemScope
          itemProp="acceptedAnswer"
          itemType="https://schema.org/Answer"
        >
          <div itemProp="text">
            Tabata may be challenging for beginners, as it requires a high level
            of fitness and intense effort. It is recommended to start with a
            lower intensity workout and gradually increase the intensity as your
            fitness level improves. It's always best to consult with a trainer
            or physician before starting any new exercise routine.
          </div>
        </div>
      </div>

      <h4 className={faqTitle}>HIIT Frequently Asked Questions (FAQ)</h4>

      <div
        itemScope
        itemProp="mainEntity"
        itemType="https://schema.org/Question"
      >
        <h5 itemProp="name" className={faqQuestion}>
          What is HIIT?
        </h5>
        <div
          itemScope
          itemProp="acceptedAnswer"
          itemType="https://schema.org/Answer"
        >
          <div itemProp="text">
            HIIT stands for High-Intensity Interval Training. It is a type of
            exercise that involves short bursts of intense activity followed by
            periods of rest or low-intensity activity.
          </div>
        </div>
      </div>

      <div
        itemScope
        itemProp="mainEntity"
        itemType="https://schema.org/Question"
      >
        <h5 itemProp="name" className={faqQuestion}>
          What are some examples of exercises that can be performed during a
          HIIT workout?
        </h5>
        <div
          itemScope
          itemProp="acceptedAnswer"
          itemType="https://schema.org/Answer"
        >
          <div itemProp="text">
            Examples of exercises that can be performed during a HIIT workout
            include running, cycling, jumping jacks, squats, and burpees.
          </div>
        </div>
      </div>

      <div
        itemScope
        itemProp="mainEntity"
        itemType="https://schema.org/Question"
      >
        <h5 itemProp="name" className={faqQuestion}>
          How many times per week should someone do HIIT to see results?
        </h5>
        <div
          itemScope
          itemProp="acceptedAnswer"
          itemType="https://schema.org/Answer"
        >
          <div itemProp="text">
            It is recommended to do HIIT 2-3 times a week, with 1-2 days of rest
            in between sessions.
          </div>
        </div>
      </div>

      <div
        itemScope
        itemProp="mainEntity"
        itemType="https://schema.org/Question"
      >
        <h5 itemProp="name" className={faqQuestion}>
          What are the benefits of HIIT training?
        </h5>
        <div
          itemScope
          itemProp="acceptedAnswer"
          itemType="https://schema.org/Answer"
        >
          <div itemProp="text">
            The benefits of HIIT training include increased cardiovascular
            fitness, improved endurance, increased muscle tone, and increased
            calorie burning. It also helps in weight loss and body fat
            reduction.
          </div>
        </div>
      </div>

      <div
        itemScope
        itemProp="mainEntity"
        itemType="https://schema.org/Question"
      >
        <h5 itemProp="name" className={faqQuestion}>
          Is HIIT suitable for beginners?
        </h5>
        <div
          itemScope
          itemProp="acceptedAnswer"
          itemType="https://schema.org/Answer"
        >
          <div itemProp="text">
            HIIT can be challenging for beginners, so it's best to start with a
            lower-intensity workout and gradually increase the intensity as your
            fitness level improves. It's always best to consult with a trainer
            or physician before starting any new exercise routine.
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

AppLandingPage.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  features: PropTypes.array,
  screenshots: PropTypes.array,
  subheadline: PropTypes.element,
  subfeatures: PropTypes.array,
  conversionLink: PropTypes.string,
  featuresHeadline: PropTypes.string,
  subfeaturesHeadline: PropTypes.string,
  breadcrumbs: PropTypes.element,
}

AppLandingPage.defaultProps = {
  title: "Intervals Pro: Tabata & HIIT",
  description: "A simple, powerful, and customizable interval timer.",
  features: [
    "HIIT (High-Intensity Interval Training)",
    "Tabata",
    "Stretching",
    "Running",
    "Boxing",
  ],
  screenshots: [],
  subheadline: "",
  subfeatures: [
    "Easy to use design",
    "Audio cues",
    "History and stats",
    "Integration with Apple Health (on available devices)",
    "Fun themes",
  ],
  conversionLink:
    "https://apps.apple.com/app/apple-store/id1516292869?pt=1963985&ct=u-1-blw-1&mt=8",
  featuresHeadline: "One timer, many workouts:",
  subfeaturesHeadline: "Packed with features:",
}

export default AppLandingPage
